import React, {Component} from "react";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { StaticImage } from "gatsby-plugin-image"

class HomeCarousel extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 1500); // simulates an async action, and hides the spinner
  }

  render() {
    const { loading } = this.state;

    if (loading) { // if your component doesn't have to wait for an async action, remove this block
      return (
        <CarouselProvider
        naturalSlideWidth={1600}
        naturalSlideHeight={700}
        totalSlides={1}
        isPlaying={false}
      >
        <Slider style={{zIndex: `-1`, background: `black`}}>
          <Slide index={0}><StaticImage src="../img/slider1.jpg" width={1920} alt="amazing limousine" /></Slide>
        </Slider>
      </CarouselProvider>
      );
    }

    return (
      <CarouselProvider
        naturalSlideWidth={1600}
        naturalSlideHeight={700}
        totalSlides={8}
        isPlaying={true}
      >
        <Slider style={{zIndex: `-1`, background: `black`}}>
          <Slide index={0}><StaticImage src="../img/slider1.jpg" width={1920} alt="limo service" /></Slide>
          <Slide index={1}><StaticImage src="../img/slider2.png" width={1920} alt="sightseeing limo service" /></Slide>
          <Slide index={2}><StaticImage src="../img/slider3.jpg" width={1920} alt="prom limo service" /></Slide>
          <Slide index={3}><StaticImage src="../img/slider4.jpg" width={1920} alt="airport limo service" /></Slide>
          <Slide index={4}><StaticImage src="../img/slider5.jpg" width={1920} alt="bachelorette limo service" /></Slide>
          <Slide index={5}><StaticImage src="../img/slider6.jpg" width={1920} alt="sports limo service" /></Slide>
          <Slide index={6}><StaticImage src="../img/slider7.jpg" width={1920} alt="hens night limo service" /></Slide>
          <Slide index={7}><StaticImage src="../img/slider9.jpg" width={1920} alt="anniversary limo service" /></Slide>
        </Slider>
      </CarouselProvider>
    )
  }
}

export default HomeCarousel;